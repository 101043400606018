const logotext = "Thunder 07 ⚡";
const meta = {
    title: "Harsha Engineer Portfolio",
    description: "I'm HARSHA VARDHAN V, AI Engineer and student.",
};

const introdata = {
    title: "うちはハルサ・ヴァルダン",
    animated: {
        first: "Undergrad Student",
        second: "Programmer",
        third: "Techie",
        fourth: "AI Engineer",
    },
    description: "I use ARCH btw.",
    your_img_url: "static/images/itachi.jpg",
};

const dataabout = {
    title: "Who am I?",
    aboutme: "I'm a AI Engineer(not yet).",
};
const worktimeline = [
    {
        jobtitle: "Associate Engineer - Data & Analytics",
        where: "Presidio",
        date: "Mar 2024 - Present",
    }
    ,
    {
        jobtitle: "NLP Intern",
        where: "Textify AI",
        date: "Jan 2024 - Mar 2024",
    }
    ,
    {
        jobtitle: "AI Intern (R&D)",
        where: "ESAB",
        date: "Nov 2023 - Jan 2024 ",
    },
    {
        jobtitle: "AI Intern (R&D)",
        where: "ESAB",
        date: "Jul 2023 - Sep 2023",
    },
    {
        jobtitle: "Software Intern",
        where: "Iamneo",
        date: "Jan 2023 - Mar 2023",
    },
];

const skills = [{
        name: "Python",
    },
    
    {
        name: "C",
    },
    
    {
        name: "Java",
    },
    
    {
        name: "Bash",
    },

    {
        name: "Artificial Intelligence",
    },

    {
        name: "Devops",
    },

    {
        name: "Web-Development",
    },

    {
        name: "Web-Exploitation",
    },
];

const dataportfolio = [{
        img: "https://d363suj4pdptk4.cloudfront.net/externalApps/c7efbbab-3f6a-497d-9dae-cbb24f5f4774/conversion/PIM/assets/133818",
        description: "Life Meter",
        link: "static/files/internship_report.pdf",
    },
];

const contactConfig = {
    YOUR_EMAIL: "harsha@harsha.engineer",
    description: "Looking forward to hearing from you!",
    YOUR_SERVICE_ID: "thundermail",
    YOUR_TEMPLATE_ID: "harsha_engineer",
    YOUR_USER_ID: "P5VRNW-fLpmeJ-rig",
};

const socialprofils = {
    github: "https://github.com/Thunder-007",
    linkedin: "https://www.linkedin.com/in/thunder-vii-1015241bb/",
    stackoverflow : "https://stackoverflow.com/users/15149644/thunder-07"
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};